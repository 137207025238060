import { HttpErrorResponse } from "@angular/common/http";

export interface AuthenticationState {
    isLoggedIn: boolean;
    accessToken: string | null;
    loginError: HttpErrorResponse | string | null;
    isLoading: boolean;
    isFirstLogin: boolean;
}

export const initialAuthenticationState: AuthenticationState = {
    isLoggedIn: false,
    accessToken: null,
    loginError: null,
    isLoading: false,
    isFirstLogin: true,
}
