import { createAction, props } from "@ngrx/store";
import { Constants } from "app/core/data/constants";


export const login = createAction(Constants.LOGIN_ACTION, props<{ username: string, password: string, rememberMe: boolean }>());
export const loginLoading = createAction(Constants.LOGIN_LOADING_ACTION, props<{ isLoading: boolean }>());
export const loginSuccess = createAction(Constants.LOGIN_SUCCESS_ACTION, props<{ accessToken: string, }>());
export const setIsFirstLogin = createAction(Constants.SET_IS_FIRST_LOGIN_ACTION,props<{ isFirstLogin: boolean }>());
export const loginFailure = createAction(Constants.LOGIN_FAILURE_ACTION, props<{ loginError: string }>());
export const logout = createAction(Constants.LOGOUT_ACTION, props<{ redirect: boolean }>());
export const oauthLoading = createAction(Constants.LOGIN_OAUTH_LOADING_ACTION, props<{ isOAuthLoading: boolean, oAuthSource: string }>());
export const loginOAuth = createAction(Constants.LOGIN_OAUTH_ACTION, props<{ ssoToken: string, source: string }>());
